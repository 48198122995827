import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"

import {PortableText} from '@portabletext/react'

import RichText from "../components/rich-text";
import { Container } from "react-bootstrap";
import { useLocation } from "@reach/router"
import { Seo } from "../components/Seo";

export default function ({ data:{legalTerms:{bannerTitle, _rawTermsContent}} }) {
    const cnt = _rawTermsContent;

    const pathLocation = useLocation();
    const pageUrl = pathLocation.href;

    const myPortableTextComponents = {
        block: {
          normal: ({children}) => <p className="terms-paragraph">{children}</p>,
          h2: ({children}) => <h2 className="heading2">{children}</h2>,
        },

        list: {
          // Ex. 1: customizing common list types
          bullet: ({children}) => { return <ul className="ul-list">{children}</ul>},
          number: ({children}) => <ol className="ol-list">{children}</ol>,
      
          // Ex. 2: rendering custom lists
          checkmarks: ({children}) => <ol className="m-auto text-lg">{children}</ol>,
        },

        listItem: {
          // Ex. 1: customizing common list types
          bullet: ({children,value:{style}}) => <li>{style == "normal" ? <p className='terms-paragraph'>{children}</p> : children}</li>,
          bullet: ({children,value:{style}}) => <li>{style == "normal" ? <p className='terms-paragraph'>{children}</p> : children}</li>,
      
          // Ex. 2: rendering custom list items
        },

        marks: {
          link: ({children, value}) => {
            const rel = !value.href.startsWith('/') ? 'noreferrer noopener' : undefined
            return (
              <a href={value.href} rel={rel}>
                {children}
              </a>
            )
          },
        },
    }


    return <Layout>
        <Seo 
            title="Terms and Conditions | Sogody" 
            description="Sogody's website terms and conditions are here for your reference. Learn about your rights, responsibilities, and how to use our site safely."
            url={pageUrl}
        />
        <div className="terms-banner">
            <Container> 
                <p className="banner-title">{bannerTitle}</p>
            </Container>
        </div>
        <div className="terms-cnt">
        <Container className="up-container"> 
            <div className="terms-content">
                {/* TODO */}
                <PortableText
                    value={cnt}
                    components={myPortableTextComponents}
                />
            </div>
        </Container>
        </div>
    </Layout>
}

export const query = graphql`
query GetLegalTerms {
    legalTerms: sanityLegalTerms {
      bannerTitle
      _rawTermsContent
    }
  }
  
`


